.container.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}
.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100% + 24px);
}
.container.fill-height > .layout {
  height: 100%;
  flex: 1 1 auto;
}
.container.fill-height > .layout.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}
.container.grid-list-xs .layout .flex {
  padding: 1px;
}
.container.grid-list-xs .layout:only-child {
  margin: -1px;
}
.container.grid-list-xs .layout:not(:only-child) {
  margin: auto -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:first-child {
  margin-top: -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:last-child {
  margin-bottom: -1px;
}
.container.grid-list-sm .layout .flex {
  padding: 2px;
}
.container.grid-list-sm .layout:only-child {
  margin: -2px;
}
.container.grid-list-sm .layout:not(:only-child) {
  margin: auto -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:first-child {
  margin-top: -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:last-child {
  margin-bottom: -2px;
}
.container.grid-list-md .layout .flex {
  padding: 4px;
}
.container.grid-list-md .layout:only-child {
  margin: -4px;
}
.container.grid-list-md .layout:not(:only-child) {
  margin: auto -4px;
}
.container.grid-list-md *:not(:only-child) .layout:first-child {
  margin-top: -4px;
}
.container.grid-list-md *:not(:only-child) .layout:last-child {
  margin-bottom: -4px;
}
.container.grid-list-lg .layout .flex {
  padding: 8px;
}
.container.grid-list-lg .layout:only-child {
  margin: -8px;
}
.container.grid-list-lg .layout:not(:only-child) {
  margin: auto -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:first-child {
  margin-top: -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:last-child {
  margin-bottom: -8px;
}
.container.grid-list-xl .layout .flex {
  padding: 12px;
}
.container.grid-list-xl .layout:only-child {
  margin: -12px;
}
.container.grid-list-xl .layout:not(:only-child) {
  margin: auto -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:first-child {
  margin-top: -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:last-child {
  margin-bottom: -12px;
}

.layout {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  min-width: 0;
}
.layout.reverse {
  flex-direction: row-reverse;
}
.layout.column {
  flex-direction: column;
}
.layout.column.reverse {
  flex-direction: column-reverse;
}
.layout.column > .flex {
  max-width: 100%;
}
.layout.wrap {
  flex-wrap: wrap;
}
.layout.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

@media all and (min-width: 0) {
  .flex.xs12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }

  .flex.order-xs12 {
    order: 12;
  }

  .flex.xs11 {
    flex-basis: 91.6666666667%;
    flex-grow: 0;
    max-width: 91.6666666667%;
  }

  .flex.order-xs11 {
    order: 11;
  }

  .flex.xs10 {
    flex-basis: 83.3333333333%;
    flex-grow: 0;
    max-width: 83.3333333333%;
  }

  .flex.order-xs10 {
    order: 10;
  }

  .flex.xs9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .flex.order-xs9 {
    order: 9;
  }

  .flex.xs8 {
    flex-basis: 66.6666666667%;
    flex-grow: 0;
    max-width: 66.6666666667%;
  }

  .flex.order-xs8 {
    order: 8;
  }

  .flex.xs7 {
    flex-basis: 58.3333333333%;
    flex-grow: 0;
    max-width: 58.3333333333%;
  }

  .flex.order-xs7 {
    order: 7;
  }

  .flex.xs6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .flex.order-xs6 {
    order: 6;
  }

  .flex.xs5 {
    flex-basis: 41.6666666667%;
    flex-grow: 0;
    max-width: 41.6666666667%;
  }

  .flex.order-xs5 {
    order: 5;
  }

  .flex.xs4 {
    flex-basis: 33.3333333333%;
    flex-grow: 0;
    max-width: 33.3333333333%;
  }

  .flex.order-xs4 {
    order: 4;
  }

  .flex.xs3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .flex.order-xs3 {
    order: 3;
  }

  .flex.xs2 {
    flex-basis: 16.6666666667%;
    flex-grow: 0;
    max-width: 16.6666666667%;
  }

  .flex.order-xs2 {
    order: 2;
  }

  .flex.xs1 {
    flex-basis: 8.3333333333%;
    flex-grow: 0;
    max-width: 8.3333333333%;
  }

  .flex.order-xs1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-xs12 {
    margin-left: 100%;
  }
  .v-application--is-rtl .flex.offset-xs12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-xs11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xs11 {
    margin-right: 91.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xs10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xs10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xs9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .flex.offset-xs9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-xs8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xs8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xs7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xs7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xs6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .flex.offset-xs6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-xs5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xs5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xs4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xs4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xs3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .flex.offset-xs3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-xs2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xs2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xs1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xs1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xs0 {
    margin-left: 0%;
  }
  .v-application--is-rtl .flex.offset-xs0 {
    margin-right: 0%;
  }
}
@media all and (min-width: 600px) {
  .flex.sm12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }

  .flex.order-sm12 {
    order: 12;
  }

  .flex.sm11 {
    flex-basis: 91.6666666667%;
    flex-grow: 0;
    max-width: 91.6666666667%;
  }

  .flex.order-sm11 {
    order: 11;
  }

  .flex.sm10 {
    flex-basis: 83.3333333333%;
    flex-grow: 0;
    max-width: 83.3333333333%;
  }

  .flex.order-sm10 {
    order: 10;
  }

  .flex.sm9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .flex.order-sm9 {
    order: 9;
  }

  .flex.sm8 {
    flex-basis: 66.6666666667%;
    flex-grow: 0;
    max-width: 66.6666666667%;
  }

  .flex.order-sm8 {
    order: 8;
  }

  .flex.sm7 {
    flex-basis: 58.3333333333%;
    flex-grow: 0;
    max-width: 58.3333333333%;
  }

  .flex.order-sm7 {
    order: 7;
  }

  .flex.sm6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .flex.order-sm6 {
    order: 6;
  }

  .flex.sm5 {
    flex-basis: 41.6666666667%;
    flex-grow: 0;
    max-width: 41.6666666667%;
  }

  .flex.order-sm5 {
    order: 5;
  }

  .flex.sm4 {
    flex-basis: 33.3333333333%;
    flex-grow: 0;
    max-width: 33.3333333333%;
  }

  .flex.order-sm4 {
    order: 4;
  }

  .flex.sm3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .flex.order-sm3 {
    order: 3;
  }

  .flex.sm2 {
    flex-basis: 16.6666666667%;
    flex-grow: 0;
    max-width: 16.6666666667%;
  }

  .flex.order-sm2 {
    order: 2;
  }

  .flex.sm1 {
    flex-basis: 8.3333333333%;
    flex-grow: 0;
    max-width: 8.3333333333%;
  }

  .flex.order-sm1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-sm12 {
    margin-left: 100%;
  }
  .v-application--is-rtl .flex.offset-sm12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-sm11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .flex.offset-sm11 {
    margin-right: 91.6666666667%;
  }

  .v-application--is-ltr .flex.offset-sm10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .flex.offset-sm10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .flex.offset-sm9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .flex.offset-sm9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-sm8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .flex.offset-sm8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .flex.offset-sm7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .flex.offset-sm7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .flex.offset-sm6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .flex.offset-sm6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-sm5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .flex.offset-sm5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .flex.offset-sm4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .flex.offset-sm4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .flex.offset-sm3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .flex.offset-sm3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-sm2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .flex.offset-sm2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .flex.offset-sm1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .flex.offset-sm1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .flex.offset-sm0 {
    margin-left: 0%;
  }
  .v-application--is-rtl .flex.offset-sm0 {
    margin-right: 0%;
  }
}
@media all and (min-width: 960px) {
  .flex.md12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }

  .flex.order-md12 {
    order: 12;
  }

  .flex.md11 {
    flex-basis: 91.6666666667%;
    flex-grow: 0;
    max-width: 91.6666666667%;
  }

  .flex.order-md11 {
    order: 11;
  }

  .flex.md10 {
    flex-basis: 83.3333333333%;
    flex-grow: 0;
    max-width: 83.3333333333%;
  }

  .flex.order-md10 {
    order: 10;
  }

  .flex.md9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .flex.order-md9 {
    order: 9;
  }

  .flex.md8 {
    flex-basis: 66.6666666667%;
    flex-grow: 0;
    max-width: 66.6666666667%;
  }

  .flex.order-md8 {
    order: 8;
  }

  .flex.md7 {
    flex-basis: 58.3333333333%;
    flex-grow: 0;
    max-width: 58.3333333333%;
  }

  .flex.order-md7 {
    order: 7;
  }

  .flex.md6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .flex.order-md6 {
    order: 6;
  }

  .flex.md5 {
    flex-basis: 41.6666666667%;
    flex-grow: 0;
    max-width: 41.6666666667%;
  }

  .flex.order-md5 {
    order: 5;
  }

  .flex.md4 {
    flex-basis: 33.3333333333%;
    flex-grow: 0;
    max-width: 33.3333333333%;
  }

  .flex.order-md4 {
    order: 4;
  }

  .flex.md3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .flex.order-md3 {
    order: 3;
  }

  .flex.md2 {
    flex-basis: 16.6666666667%;
    flex-grow: 0;
    max-width: 16.6666666667%;
  }

  .flex.order-md2 {
    order: 2;
  }

  .flex.md1 {
    flex-basis: 8.3333333333%;
    flex-grow: 0;
    max-width: 8.3333333333%;
  }

  .flex.order-md1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-md12 {
    margin-left: 100%;
  }
  .v-application--is-rtl .flex.offset-md12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-md11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .flex.offset-md11 {
    margin-right: 91.6666666667%;
  }

  .v-application--is-ltr .flex.offset-md10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .flex.offset-md10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .flex.offset-md9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .flex.offset-md9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-md8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .flex.offset-md8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .flex.offset-md7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .flex.offset-md7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .flex.offset-md6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .flex.offset-md6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-md5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .flex.offset-md5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .flex.offset-md4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .flex.offset-md4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .flex.offset-md3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .flex.offset-md3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-md2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .flex.offset-md2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .flex.offset-md1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .flex.offset-md1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .flex.offset-md0 {
    margin-left: 0%;
  }
  .v-application--is-rtl .flex.offset-md0 {
    margin-right: 0%;
  }
}
@media all and (min-width: 1264px) {
  .flex.lg12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }

  .flex.order-lg12 {
    order: 12;
  }

  .flex.lg11 {
    flex-basis: 91.6666666667%;
    flex-grow: 0;
    max-width: 91.6666666667%;
  }

  .flex.order-lg11 {
    order: 11;
  }

  .flex.lg10 {
    flex-basis: 83.3333333333%;
    flex-grow: 0;
    max-width: 83.3333333333%;
  }

  .flex.order-lg10 {
    order: 10;
  }

  .flex.lg9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .flex.order-lg9 {
    order: 9;
  }

  .flex.lg8 {
    flex-basis: 66.6666666667%;
    flex-grow: 0;
    max-width: 66.6666666667%;
  }

  .flex.order-lg8 {
    order: 8;
  }

  .flex.lg7 {
    flex-basis: 58.3333333333%;
    flex-grow: 0;
    max-width: 58.3333333333%;
  }

  .flex.order-lg7 {
    order: 7;
  }

  .flex.lg6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .flex.order-lg6 {
    order: 6;
  }

  .flex.lg5 {
    flex-basis: 41.6666666667%;
    flex-grow: 0;
    max-width: 41.6666666667%;
  }

  .flex.order-lg5 {
    order: 5;
  }

  .flex.lg4 {
    flex-basis: 33.3333333333%;
    flex-grow: 0;
    max-width: 33.3333333333%;
  }

  .flex.order-lg4 {
    order: 4;
  }

  .flex.lg3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .flex.order-lg3 {
    order: 3;
  }

  .flex.lg2 {
    flex-basis: 16.6666666667%;
    flex-grow: 0;
    max-width: 16.6666666667%;
  }

  .flex.order-lg2 {
    order: 2;
  }

  .flex.lg1 {
    flex-basis: 8.3333333333%;
    flex-grow: 0;
    max-width: 8.3333333333%;
  }

  .flex.order-lg1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-lg12 {
    margin-left: 100%;
  }
  .v-application--is-rtl .flex.offset-lg12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-lg11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .flex.offset-lg11 {
    margin-right: 91.6666666667%;
  }

  .v-application--is-ltr .flex.offset-lg10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .flex.offset-lg10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .flex.offset-lg9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .flex.offset-lg9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-lg8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .flex.offset-lg8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .flex.offset-lg7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .flex.offset-lg7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .flex.offset-lg6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .flex.offset-lg6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-lg5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .flex.offset-lg5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .flex.offset-lg4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .flex.offset-lg4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .flex.offset-lg3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .flex.offset-lg3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-lg2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .flex.offset-lg2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .flex.offset-lg1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .flex.offset-lg1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .flex.offset-lg0 {
    margin-left: 0%;
  }
  .v-application--is-rtl .flex.offset-lg0 {
    margin-right: 0%;
  }
}
@media all and (min-width: 1904px) {
  .flex.xl12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }

  .flex.order-xl12 {
    order: 12;
  }

  .flex.xl11 {
    flex-basis: 91.6666666667%;
    flex-grow: 0;
    max-width: 91.6666666667%;
  }

  .flex.order-xl11 {
    order: 11;
  }

  .flex.xl10 {
    flex-basis: 83.3333333333%;
    flex-grow: 0;
    max-width: 83.3333333333%;
  }

  .flex.order-xl10 {
    order: 10;
  }

  .flex.xl9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .flex.order-xl9 {
    order: 9;
  }

  .flex.xl8 {
    flex-basis: 66.6666666667%;
    flex-grow: 0;
    max-width: 66.6666666667%;
  }

  .flex.order-xl8 {
    order: 8;
  }

  .flex.xl7 {
    flex-basis: 58.3333333333%;
    flex-grow: 0;
    max-width: 58.3333333333%;
  }

  .flex.order-xl7 {
    order: 7;
  }

  .flex.xl6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .flex.order-xl6 {
    order: 6;
  }

  .flex.xl5 {
    flex-basis: 41.6666666667%;
    flex-grow: 0;
    max-width: 41.6666666667%;
  }

  .flex.order-xl5 {
    order: 5;
  }

  .flex.xl4 {
    flex-basis: 33.3333333333%;
    flex-grow: 0;
    max-width: 33.3333333333%;
  }

  .flex.order-xl4 {
    order: 4;
  }

  .flex.xl3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .flex.order-xl3 {
    order: 3;
  }

  .flex.xl2 {
    flex-basis: 16.6666666667%;
    flex-grow: 0;
    max-width: 16.6666666667%;
  }

  .flex.order-xl2 {
    order: 2;
  }

  .flex.xl1 {
    flex-basis: 8.3333333333%;
    flex-grow: 0;
    max-width: 8.3333333333%;
  }

  .flex.order-xl1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-xl12 {
    margin-left: 100%;
  }
  .v-application--is-rtl .flex.offset-xl12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-xl11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xl11 {
    margin-right: 91.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xl10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xl10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xl9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .flex.offset-xl9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-xl8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xl8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xl7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xl7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xl6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .flex.offset-xl6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-xl5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xl5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xl4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xl4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xl3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .flex.offset-xl3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-xl2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .flex.offset-xl2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .flex.offset-xl1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .flex.offset-xl1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .flex.offset-xl0 {
    margin-left: 0%;
  }
  .v-application--is-rtl .flex.offset-xl0 {
    margin-right: 0%;
  }
}
.flex,
.child-flex > * {
  flex: 1 1 auto;
  max-width: 100%;
}
.flex.grow-shrink-0,
.child-flex > *.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

.spacer {
  flex-grow: 1 !important;
}

.grow {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
}

.shrink {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.fill-height {
  height: 100%;
}