.theme--light.v-progress-linear {
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-progress-linear {
  color: #FFFFFF;
}

.v-progress-linear {
  background: transparent;
  overflow: hidden;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  width: 100%;
}

.v-progress-linear__buffer {
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  transition: inherit;
  width: 100%;
}

.v-progress-linear--reverse .v-progress-linear__buffer {
  left: auto;
  right: 0;
}

.v-progress-linear__background {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: inherit;
}

.v-progress-linear--reverse .v-progress-linear__background {
  left: auto;
  right: 0;
}

.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.v-progress-linear--reverse .v-progress-linear__content {
  left: auto;
  right: 0;
}

.v-progress-linear__determinate {
  height: inherit;
  left: 0;
  position: absolute;
  transition: inherit;
}

.v-progress-linear--reverse .v-progress-linear__determinate {
  left: auto;
  right: 0;
}

.v-progress-linear .v-progress-linear__indeterminate .long, .v-progress-linear .v-progress-linear__indeterminate .short {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  background-color: inherit;
  bottom: 0;
  height: inherit;
  left: 0;
  position: absolute;
  right: auto;
  top: 0;
  width: auto;
  will-change: left, right;
}
.v-progress-linear .v-progress-linear__indeterminate--active .long {
  -webkit-animation-name: indeterminate-ltr;
          animation-name: indeterminate-ltr;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear .v-progress-linear__indeterminate--active .short {
  -webkit-animation-name: indeterminate-short-ltr;
          animation-name: indeterminate-short-ltr;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .long, .v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  left: auto;
  right: 0;
}
.v-progress-linear--reverse .v-progress-linear__indeterminate--active .long {
  -webkit-animation-name: indeterminate-rtl;
          animation-name: indeterminate-rtl;
}
.v-progress-linear--reverse .v-progress-linear__indeterminate--active .short {
  -webkit-animation-name: indeterminate-short-rtl;
          animation-name: indeterminate-short-rtl;
}

.v-progress-linear__stream {
  -webkit-animation: stream-ltr 0.25s infinite linear;
          animation: stream-ltr 0.25s infinite linear;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  border-color: currentColor;
  border-top: 4px dotted;
  bottom: 0;
  left: auto;
  right: -8px;
  opacity: 0.3;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 2px);
  transition: inherit;
}

.v-progress-linear--reverse .v-progress-linear__stream {
  -webkit-animation: stream-rtl 0.25s infinite linear;
          animation: stream-rtl 0.25s infinite linear;
  left: -8px;
  right: auto;
}

.v-progress-linear__wrapper {
  overflow: hidden;
  position: relative;
  transition: inherit;
}

.v-progress-linear--absolute,
.v-progress-linear--fixed {
  left: 0;
  z-index: 1;
}

.v-progress-linear--absolute {
  position: absolute;
}

.v-progress-linear--fixed {
  position: fixed;
}

.v-progress-linear--reactive .v-progress-linear__content {
  pointer-events: none;
}

.v-progress-linear--rounded {
  border-radius: 4px;
}

.v-progress-linear--striped .v-progress-linear__determinate {
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0.25) 75%, transparent 0, transparent);
  background-size: 40px 40px;
  background-repeat: repeat;
}

.v-progress-linear--query .v-progress-linear__indeterminate--active .long {
  -webkit-animation-name: query-ltr;
          animation-name: query-ltr;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear--query .v-progress-linear__indeterminate--active .short {
  -webkit-animation-name: query-short-ltr;
          animation-name: query-short-ltr;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear--query.v-progress-linear--reverse .v-progress-linear__indeterminate--active .long {
  -webkit-animation-name: query-rtl;
          animation-name: query-rtl;
}
.v-progress-linear--query.v-progress-linear--reverse .v-progress-linear__indeterminate--active .short {
  -webkit-animation-name: query-short-rtl;
          animation-name: query-short-rtl;
}

.v-progress-linear--visible .v-progress-linear__indeterminate--active .long, .v-progress-linear--visible .v-progress-linear__indeterminate--active .short {
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.v-progress-linear--visible .v-progress-linear__stream {
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: -35%;
    right: 100%;
  }
}
@keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: -35%;
    right: 100%;
  }
}
@-webkit-keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%;
  }
  60% {
    left: -8%;
    right: 107%;
  }
  100% {
    left: -8%;
    right: 107%;
  }
}
@keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%;
  }
  60% {
    left: -8%;
    right: 107%;
  }
  100% {
    left: -8%;
    right: 107%;
  }
}
@-webkit-keyframes query-ltr {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@keyframes query-ltr {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@-webkit-keyframes query-rtl {
  0% {
    right: 100%;
    left: -90%;
  }
  60% {
    right: 100%;
    left: -90%;
  }
  100% {
    right: -35%;
    left: 100%;
  }
}
@keyframes query-rtl {
  0% {
    right: 100%;
    left: -90%;
  }
  60% {
    right: 100%;
    left: -90%;
  }
  100% {
    right: -35%;
    left: 100%;
  }
}
@-webkit-keyframes query-short-ltr {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@keyframes query-short-ltr {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@-webkit-keyframes query-short-rtl {
  0% {
    right: 100%;
    left: -200%;
  }
  60% {
    right: -8%;
    left: 107%;
  }
  100% {
    right: -8%;
    left: 107%;
  }
}
@keyframes query-short-rtl {
  0% {
    right: 100%;
    left: -200%;
  }
  60% {
    right: -8%;
    left: 107%;
  }
  100% {
    right: -8%;
    left: 107%;
  }
}
@-webkit-keyframes stream-ltr {
  to {
    transform: translateX(-8px);
  }
}
@keyframes stream-ltr {
  to {
    transform: translateX(-8px);
  }
}
@-webkit-keyframes stream-rtl {
  to {
    transform: translateX(8px);
  }
}
@keyframes stream-rtl {
  to {
    transform: translateX(8px);
  }
}