.theme--light.v-radio--is-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.theme--dark.v-radio--is-disabled label {
  color: rgba(255, 255, 255, 0.5);
}

.v-radio {
  align-items: center;
  display: flex;
  height: auto;
  outline: none;
}
.v-radio--is-disabled {
  pointer-events: none;
  cursor: default;
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 16px;
}