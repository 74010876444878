.theme--light.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #FFFFFF;
}

.theme--dark.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #303030;
}

.v-otp-input {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-right: -4px;
  margin-left: -4px;
}
.v-otp-input input {
  text-align: center;
}
.v-otp-input .v-input {
  margin: 0;
  flex: 1 0 32px;
  max-width: 100%;
  width: 100%;
  padding: 4px;
}
.v-otp-input .v-input.v-otp-input--plain fieldset {
  display: none;
}
.v-otp-input .v-input input[type=number]::-webkit-outer-spin-button,
.v-otp-input .v-input input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-otp-input .v-input input[type=number] {
  -moz-appearance: textfield;
}