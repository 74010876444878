.theme--light.v-overflow-btn.theme--light.v-overflow-btn > .v-input__control > .v-input__slot {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme--light.v-overflow-btn:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #FFFFFF;
}
.theme--light.v-overflow-btn.v-overflow-btn--segmented .v-input__append-inner {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-overflow-btn.theme--dark.v-overflow-btn > .v-input__control > .v-input__slot {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-overflow-btn:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #1E1E1E;
}
.theme--dark.v-overflow-btn.v-overflow-btn--segmented .v-input__append-inner {
  border-left: thin solid rgba(255, 255, 255, 0.12);
}

.v-autocomplete__content.v-menu__content {
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
}
.v-autocomplete__content.v-menu__content .v-select-list {
  border-radius: 0 0 4px 4px;
}

.v-overflow-btn {
  margin-top: 12px;
  padding-top: 0;
}
.v-overflow-btn:not(.v-overflow-btn--editable) > .v-input__control > .v-input__slot {
  cursor: pointer;
}
.v-overflow-btn .v-input__slot {
  border-width: 2px 0;
  border-style: solid;
}
.v-overflow-btn .v-input__slot:before {
  display: none;
}
.v-overflow-btn .v-select__slot {
  height: 48px;
}
.v-overflow-btn.v-input--dense .v-select__slot {
  height: 38px;
}
.v-overflow-btn.v-input--dense input {
  cursor: pointer;
}
.v-application--is-ltr .v-overflow-btn.v-input--dense input {
  margin-left: 16px;
}
.v-application--is-rtl .v-overflow-btn.v-input--dense input {
  margin-right: 16px;
}
.v-application--is-ltr .v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 16px;
}
.v-application--is-rtl .v-overflow-btn .v-select__selection--comma:first-child {
  margin-right: 16px;
}
.v-overflow-btn .v-input__slot {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-overflow-btn .v-input__slot::before, .v-overflow-btn .v-input__slot::after {
  display: none;
}
.v-overflow-btn .v-label {
  top: calc(50% - 10px);
}
.v-application--is-ltr .v-overflow-btn .v-label {
  margin-left: 16px;
}
.v-application--is-rtl .v-overflow-btn .v-label {
  margin-right: 16px;
}
.v-overflow-btn .v-input__append-inner {
  align-items: center;
  align-self: auto;
  flex-shrink: 0;
  height: 48px;
  margin-top: 0;
  padding: 0 4px;
  width: 42px;
}
.v-overflow-btn .v-input__append-outer,
.v-overflow-btn .v-input__prepend-outer {
  margin-bottom: 12px;
  margin-top: 12px;
}
.v-overflow-btn .v-input__control::before {
  height: 1px;
  top: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-overflow-btn.v-input--is-focused .v-input__slot, .v-overflow-btn.v-select--is-menu-active .v-input__slot {
  border-color: transparent !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
.v-overflow-btn.v-input--is-focused .v-input__slot {
  border-radius: 4px;
}
.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  border-radius: 4px 4px 0 0;
}
.v-overflow-btn .v-select__selections {
  width: 0px;
}
.v-overflow-btn--segmented .v-input__slot {
  border-width: thin 0;
}
.v-overflow-btn--segmented .v-select__selections {
  flex-wrap: nowrap;
}
.v-overflow-btn--segmented .v-select__selections .v-btn {
  border-radius: 0;
  margin: 0;
  height: 48px;
  width: 100%;
}
.v-application--is-ltr .v-overflow-btn--segmented .v-select__selections .v-btn {
  margin-right: -16px;
}
.v-application--is-rtl .v-overflow-btn--segmented .v-select__selections .v-btn {
  margin-left: -16px;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content {
  justify-content: start;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content::before {
  background-color: transparent;
}
.v-overflow-btn--editable .v-select__slot input {
  cursor: text;
  padding: 8px 16px;
}
.v-overflow-btn--editable .v-input__append-inner,
.v-overflow-btn--editable .v-input__append-inner * {
  cursor: pointer;
}