.container {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
@media (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}
.container--fluid {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: -12px;
}
.row + .row {
  margin-top: 12px;
}
.row + .row--dense {
  margin-top: 4px;
}
.row--dense {
  margin: -4px;
}
.row--dense > .col,
.row--dense > [class*=col-] {
  padding: 4px;
}
.row.no-gutters {
  margin: 0;
}
.row.no-gutters > .col,
.row.no-gutters > [class*=col-] {
  padding: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.v-application--is-ltr .offset-1 {
  margin-left: 8.3333333333%;
}
.v-application--is-rtl .offset-1 {
  margin-right: 8.3333333333%;
}

.v-application--is-ltr .offset-2 {
  margin-left: 16.6666666667%;
}
.v-application--is-rtl .offset-2 {
  margin-right: 16.6666666667%;
}

.v-application--is-ltr .offset-3 {
  margin-left: 25%;
}
.v-application--is-rtl .offset-3 {
  margin-right: 25%;
}

.v-application--is-ltr .offset-4 {
  margin-left: 33.3333333333%;
}
.v-application--is-rtl .offset-4 {
  margin-right: 33.3333333333%;
}

.v-application--is-ltr .offset-5 {
  margin-left: 41.6666666667%;
}
.v-application--is-rtl .offset-5 {
  margin-right: 41.6666666667%;
}

.v-application--is-ltr .offset-6 {
  margin-left: 50%;
}
.v-application--is-rtl .offset-6 {
  margin-right: 50%;
}

.v-application--is-ltr .offset-7 {
  margin-left: 58.3333333333%;
}
.v-application--is-rtl .offset-7 {
  margin-right: 58.3333333333%;
}

.v-application--is-ltr .offset-8 {
  margin-left: 66.6666666667%;
}
.v-application--is-rtl .offset-8 {
  margin-right: 66.6666666667%;
}

.v-application--is-ltr .offset-9 {
  margin-left: 75%;
}
.v-application--is-rtl .offset-9 {
  margin-right: 75%;
}

.v-application--is-ltr .offset-10 {
  margin-left: 83.3333333333%;
}
.v-application--is-rtl .offset-10 {
  margin-right: 83.3333333333%;
}

.v-application--is-ltr .offset-11 {
  margin-left: 91.6666666667%;
}
.v-application--is-rtl .offset-11 {
  margin-right: 91.6666666667%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-application--is-ltr .offset-sm-0 {
    margin-left: 0;
  }
  .v-application--is-rtl .offset-sm-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .offset-sm-1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .offset-sm-2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .offset-sm-3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .offset-sm-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .offset-sm-4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .offset-sm-5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .offset-sm-6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .offset-sm-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .offset-sm-7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .offset-sm-8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .offset-sm-9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .offset-sm-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .offset-sm-10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .offset-sm-11 {
    margin-right: 91.6666666667%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-application--is-ltr .offset-md-0 {
    margin-left: 0;
  }
  .v-application--is-rtl .offset-md-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .offset-md-1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .offset-md-2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .offset-md-3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .offset-md-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .offset-md-4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .offset-md-5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .offset-md-6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .offset-md-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .offset-md-7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .offset-md-8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .offset-md-9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .offset-md-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .offset-md-10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .offset-md-11 {
    margin-right: 91.6666666667%;
  }
}
@media (min-width: 1264px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-application--is-ltr .offset-lg-0 {
    margin-left: 0;
  }
  .v-application--is-rtl .offset-lg-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .offset-lg-1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .offset-lg-2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .offset-lg-3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .offset-lg-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .offset-lg-4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .offset-lg-5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .offset-lg-6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .offset-lg-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .offset-lg-7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .offset-lg-8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .offset-lg-9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .offset-lg-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .offset-lg-10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .offset-lg-11 {
    margin-right: 91.6666666667%;
  }
}
@media (min-width: 1904px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-application--is-ltr .offset-xl-0 {
    margin-left: 0;
  }
  .v-application--is-rtl .offset-xl-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .v-application--is-rtl .offset-xl-1 {
    margin-right: 8.3333333333%;
  }

  .v-application--is-ltr .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .v-application--is-rtl .offset-xl-2 {
    margin-right: 16.6666666667%;
  }

  .v-application--is-ltr .offset-xl-3 {
    margin-left: 25%;
  }
  .v-application--is-rtl .offset-xl-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .v-application--is-rtl .offset-xl-4 {
    margin-right: 33.3333333333%;
  }

  .v-application--is-ltr .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .v-application--is-rtl .offset-xl-5 {
    margin-right: 41.6666666667%;
  }

  .v-application--is-ltr .offset-xl-6 {
    margin-left: 50%;
  }
  .v-application--is-rtl .offset-xl-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .v-application--is-rtl .offset-xl-7 {
    margin-right: 58.3333333333%;
  }

  .v-application--is-ltr .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .v-application--is-rtl .offset-xl-8 {
    margin-right: 66.6666666667%;
  }

  .v-application--is-ltr .offset-xl-9 {
    margin-left: 75%;
  }
  .v-application--is-rtl .offset-xl-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .v-application--is-rtl .offset-xl-10 {
    margin-right: 83.3333333333%;
  }

  .v-application--is-ltr .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .v-application--is-rtl .offset-xl-11 {
    margin-right: 91.6666666667%;
  }
}