.v-virtual-table {
  position: relative;
}

.v-virtual-table__wrapper {
  display: flex;
}

.v-virtual-table__table {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}