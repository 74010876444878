.theme--light.v-color-picker .v-color-picker__input input {
  border: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-color-picker span {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-color-picker .v-color-picker__dot, .theme--light.v-color-picker .v-color-picker__color {
  background-color: rgba(255, 255, 255, 0);
}

.theme--dark.v-color-picker .v-color-picker__input input {
  border: thin solid rgba(255, 255, 255, 0.12);
  color: #FFFFFF;
}
.theme--dark.v-color-picker span {
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-color-picker .v-color-picker__dot, .theme--dark.v-color-picker .v-color-picker__color {
  background-color: rgba(255, 255, 255, 0.12);
}

.v-color-picker {
  align-self: flex-start;
  border-radius: 4px;
  contain: content;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.v-color-picker__controls {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.v-color-picker--flat {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.v-color-picker--flat .v-color-picker__track:not(.v-input--is-disabled) .v-slider__thumb {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}