.v-virtual-scroll {
  display: block;
  flex: 1 1 auto;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  position: relative;
}
.v-virtual-scroll__container {
  display: block;
}
.v-virtual-scroll__item {
  left: 0;
  position: absolute;
  right: 0;
}