.v-chip:not(.v-chip--outlined).primary, .v-chip:not(.v-chip--outlined).secondary, .v-chip:not(.v-chip--outlined).accent, .v-chip:not(.v-chip--outlined).success, .v-chip:not(.v-chip--outlined).error, .v-chip:not(.v-chip--outlined).warning, .v-chip:not(.v-chip--outlined).info {
  color: #FFFFFF;
}

.theme--light.v-chip {
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #e0e0e0;
}
.theme--light.v-chip:hover::before {
  opacity: 0.04;
}
.theme--light.v-chip:focus::before {
  opacity: 0.12;
}
.theme--light.v-chip--active:hover::before, .theme--light.v-chip--active::before {
  opacity: 0.12;
}
.theme--light.v-chip--active:focus::before {
  opacity: 0.16;
}

.theme--dark.v-chip {
  border-color: rgba(255, 255, 255, 0.12);
  color: #FFFFFF;
}
.theme--dark.v-chip:not(.v-chip--active) {
  background: #555;
}
.theme--dark.v-chip:hover::before {
  opacity: 0.08;
}
.theme--dark.v-chip:focus::before {
  opacity: 0.24;
}
.theme--dark.v-chip--active:hover::before, .theme--dark.v-chip--active::before {
  opacity: 0.24;
}
.theme--dark.v-chip--active:focus::before {
  opacity: 0.32;
}

.v-chip {
  align-items: center;
  cursor: default;
  display: inline-flex;
  line-height: 20px;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-decoration: none;
  transition-duration: 0.28s;
  transition-property: box-shadow, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  white-space: nowrap;
}
.v-chip:before {
  background-color: currentColor;
  bottom: 0;
  border-radius: inherit;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
}
.v-chip .v-avatar {
  height: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}
.v-chip .v-icon {
  font-size: 24px;
}
.v-application--is-ltr .v-chip .v-avatar--left,
.v-application--is-ltr .v-chip .v-icon--left {
  margin-left: -6px;
  margin-right: 6px;
}
.v-application--is-ltr .v-chip .v-avatar--right,
.v-application--is-ltr .v-chip .v-icon--right {
  margin-left: 6px;
  margin-right: -6px;
}
.v-application--is-rtl .v-chip .v-avatar--left,
.v-application--is-rtl .v-chip .v-icon--left {
  margin-left: 6px;
  margin-right: -6px;
}
.v-application--is-rtl .v-chip .v-avatar--right,
.v-application--is-rtl .v-chip .v-icon--right {
  margin-left: -6px;
  margin-right: 6px;
}
.v-chip:not(.v-chip--no-color) .v-icon {
  color: inherit;
}

.v-chip .v-chip__close.v-icon {
  font-size: 18px;
  max-height: 18px;
  max-width: 18px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-right: -4px;
}
.v-application--is-rtl .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-left: -4px;
}
.v-chip .v-chip__close.v-icon:hover, .v-chip .v-chip__close.v-icon:focus, .v-chip .v-chip__close.v-icon:active {
  opacity: 0.72;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
}

.v-chip--active .v-icon {
  color: inherit;
}

.v-chip--link::before {
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-chip--link:focus::before {
  opacity: 0.32;
}

.v-chip--clickable {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-chip--clickable:active {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.v-chip--disabled {
  opacity: 0.4;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.v-chip__filter {
  max-width: 24px;
}
.v-chip__filter.v-icon {
  color: inherit;
}
.v-chip__filter.expand-x-transition-leave-active, .v-chip__filter.expand-x-transition-enter {
  margin: 0;
}

.v-chip--pill .v-chip__filter {
  margin: 0 16px 0 0;
}
.v-chip--pill .v-avatar {
  height: 32px !important;
  width: 32px !important;
}
.v-application--is-ltr .v-chip--pill .v-avatar--left {
  margin-left: -12px;
}
.v-application--is-ltr .v-chip--pill .v-avatar--right {
  margin-right: -12px;
}
.v-application--is-rtl .v-chip--pill .v-avatar--left {
  margin-right: -12px;
}
.v-application--is-rtl .v-chip--pill .v-avatar--right {
  margin-left: -12px;
}

.v-chip--label {
  border-radius: 4px !important;
}

.v-chip.v-chip--outlined {
  border-width: thin;
  border-style: solid;
}
.v-chip.v-chip--outlined.v-chip--active:before {
  opacity: 0.08;
}
.v-chip.v-chip--outlined .v-icon {
  color: inherit;
}
.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: transparent !important;
}

.v-chip.v-chip--selected {
  background: transparent;
}
.v-chip.v-chip--selected:after {
  opacity: 0.28;
}

.v-chip.v-size--x-small {
  border-radius: 8px;
  font-size: 10px;
  height: 16px;
}
.v-chip.v-size--small {
  border-radius: 12px;
  font-size: 12px;
  height: 24px;
}
.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
}
.v-chip.v-size--large {
  border-radius: 27px;
  font-size: 16px;
  height: 54px;
}
.v-chip.v-size--x-large {
  border-radius: 33px;
  font-size: 18px;
  height: 66px;
}