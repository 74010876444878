.theme--light.v-pagination .v-pagination__item {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #FFFFFF;
}
.theme--light.v-pagination .v-pagination__navigation {
  background: #FFFFFF;
}

.theme--dark.v-pagination .v-pagination__item {
  background: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-pagination .v-pagination__item--active {
  color: #FFFFFF;
}
.theme--dark.v-pagination .v-pagination__navigation {
  background: #1E1E1E;
}

.v-pagination {
  align-items: center;
  display: inline-flex;
  list-style-type: none;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  width: 100%;
}
.v-pagination.v-pagination {
  padding-left: 0;
}
.v-pagination > li {
  align-items: center;
  display: flex;
}
.v-pagination--circle .v-pagination__item,
.v-pagination--circle .v-pagination__more,
.v-pagination--circle .v-pagination__navigation {
  border-radius: 50%;
}
.v-pagination--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-pagination__item {
  background: transparent;
  border-radius: 4px;
  font-size: 1rem;
  height: 34px;
  margin: 0.3rem;
  min-width: 34px;
  padding: 0 5px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-pagination__item--active {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.v-pagination__navigation {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 32px;
  width: 32px;
  margin: 0.3rem 10px;
}
.v-pagination__navigation .v-icon {
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  vertical-align: middle;
}
.v-pagination__navigation--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.v-pagination__more {
  margin: 0.3rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  height: 32px;
  width: 32px;
}