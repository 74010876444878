.v-list.primary > .v-list-item, .v-list.secondary > .v-list-item, .v-list.accent > .v-list-item, .v-list.success > .v-list-item, .v-list.error > .v-list-item, .v-list.warning > .v-list-item, .v-list.info > .v-list-item {
  color: #FFFFFF;
}

.theme--light.v-list {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-list .v-list--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-list .v-list-group--active:before,
.theme--light.v-list .v-list-group--active:after {
  background: rgba(0, 0, 0, 0.12);
}

.theme--dark.v-list {
  background: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-list .v-list--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-list .v-list-group--active:before,
.theme--dark.v-list .v-list-group--active:after {
  background: rgba(255, 255, 255, 0.12);
}

.v-sheet.v-list {
  border-radius: 0;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-list.v-sheet--shaped {
  border-radius: 0;
}

.v-list {
  display: block;
  padding: 8px 0;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.v-list--disabled {
  pointer-events: none;
}

.v-list--flat .v-list-item:before {
  display: none;
}

.v-list--dense .v-subheader {
  font-size: 0.75rem;
  height: 40px;
  padding: 0 8px;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}
.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
.v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
  margin-bottom: 4px;
}

.v-list--nav {
  padding-left: 8px;
  padding-right: 8px;
}
.v-list--nav .v-list-item {
  padding: 0 8px;
}
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 4px;
}

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before,
.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-bottom-right-radius: 32px !important;
  border-top-right-radius: 32px !important;
}
.v-application--is-rtl .v-list.v-sheet--shaped .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped .v-list-item::before,
.v-application--is-rtl .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-bottom-left-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item::before,
.v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item > .v-ripple__container {
  border-bottom-right-radius: 42.6666666667px !important;
  border-top-right-radius: 42.6666666667px !important;
}
.v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item::before,
.v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item > .v-ripple__container {
  border-bottom-left-radius: 42.6666666667px !important;
  border-top-left-radius: 42.6666666667px !important;
}
.v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item::before,
.v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item > .v-ripple__container {
  border-bottom-right-radius: 58.6666666667px !important;
  border-top-right-radius: 58.6666666667px !important;
}
.v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item::before,
.v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item > .v-ripple__container {
  border-bottom-left-radius: 58.6666666667px !important;
  border-top-left-radius: 58.6666666667px !important;
}
.v-application--is-ltr .v-list.v-sheet--shaped {
  padding-right: 8px;
}
.v-application--is-rtl .v-list.v-sheet--shaped {
  padding-left: 8px;
}

.v-list--rounded {
  padding: 8px;
}
.v-list--rounded .v-list-item, .v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 32px !important;
}
.v-list--rounded.v-list--two-line .v-list-item, .v-list--rounded.v-list--two-line .v-list-item::before,
.v-list--rounded.v-list--two-line .v-list-item > .v-ripple__container {
  border-radius: 42.6666666667px !important;
}
.v-list--rounded.v-list--three-line .v-list-item, .v-list--rounded.v-list--three-line .v-list-item::before,
.v-list--rounded.v-list--three-line .v-list-item > .v-ripple__container {
  border-radius: 58.6666666667px !important;
}

.v-list--subheader {
  padding-top: 0;
}