.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  align-self: center;
  margin: 0;
  min-width: 48px;
  justify-content: flex-end;
}

.v-list-group--sub-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.v-list-group__header.v-list-item--active:not(:hover):not(:focus):before {
  opacity: 0;
}

.v-list-group__items {
  flex: 1 1 auto;
}
.v-list-group__items .v-list-item,
.v-list-group__items .v-list-group__items {
  overflow: hidden;
}

.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
  transform: rotate(-180deg);
}
.v-list-group--active > .v-list-group__header.v-list-group__header--sub-group > .v-list-group__header__prepend-icon .v-icon {
  transform: rotate(-180deg);
}
.v-list-group--active > .v-list-group__header .v-list-item,
.v-list-group--active > .v-list-group__header .v-list-item__content,
.v-list-group--active > .v-list-group__header .v-list-group__header__prepend-icon .v-icon {
  color: inherit;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-item__action:first-child,
.v-application--is-ltr .v-list-group--sub-group .v-list-item__avatar:first-child,
.v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-right: 16px;
}
.v-application--is-rtl .v-list-group--sub-group .v-list-item__action:first-child,
.v-application--is-rtl .v-list-group--sub-group .v-list-item__avatar:first-child,
.v-application--is-rtl .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-left: 16px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 32px;
}
.v-application--is-rtl .v-list-group--sub-group .v-list-group__header {
  padding-right: 32px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-left: 40px;
}
.v-application--is-rtl .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-right: 40px;
}
.v-list-group--sub-group.v-list-group--active .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
  transform: rotate(-180deg);
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 72px;
}
.v-application--is-rtl .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-right: 72px;
}
.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 88px;
}
.v-application--is-rtl .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-right: 88px;
}

.v-application--is-ltr .v-list--dense .v-list-group--sub-group .v-list-group__header {
  padding-left: 24px;
}
.v-application--is-rtl .v-list--dense .v-list-group--sub-group .v-list-group__header {
  padding-right: 24px;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 64px;
}
.v-application--is-rtl .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-right: 64px;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 80px;
}
.v-application--is-rtl .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-right: 80px;
}