.v-color-picker__edit {
  margin-top: 24px;
  display: flex;
}

.v-color-picker__input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.v-application--is-ltr .v-color-picker__input:not(:last-child) {
  margin-right: 8px;
}
.v-application--is-rtl .v-color-picker__input:not(:last-child) {
  margin-left: 8px;
}
.v-color-picker__input input {
  border-radius: 4px;
  margin-bottom: 8px;
  min-width: 0;
  outline: none;
  text-align: center;
  width: 100%;
  height: 28px;
}
.v-color-picker__input span {
  font-size: 0.75rem;
}