.theme--light.v-sheet {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-sheet--outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-sheet {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-sheet--outlined {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.v-sheet {
  border-radius: 0;
}
.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-sheet--shaped {
  border-radius: 24px 0;
}