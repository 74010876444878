.v-window {
  overflow: hidden;
}
.v-window__container {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-window__container--is-active {
  overflow: hidden;
}
.v-window__prev, .v-window__next {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  margin: 0 16px;
  top: calc(50% - 20px);
  z-index: 1;
}
.v-window__prev .v-btn:hover, .v-window__next .v-btn:hover {
  background: none;
}
.v-application--is-ltr .v-window__prev {
  left: 0;
}
.v-application--is-rtl .v-window__prev {
  right: 0;
}
.v-application--is-ltr .v-window__next {
  right: 0;
}
.v-application--is-rtl .v-window__next {
  left: 0;
}
.v-window--show-arrows-on-hover {
  overflow: hidden;
}
.v-window--show-arrows-on-hover .v-window__next,
.v-window--show-arrows-on-hover .v-window__prev {
  transition: 0.2s transform cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-application--is-ltr .v-window--show-arrows-on-hover .v-window__prev {
  transform: translateX(-200%);
}
.v-application--is-rtl .v-window--show-arrows-on-hover .v-window__prev {
  transform: translateX(200%);
}
.v-application--is-ltr .v-window--show-arrows-on-hover .v-window__next {
  transform: translateX(200%);
}
.v-application--is-rtl .v-window--show-arrows-on-hover .v-window__next {
  transform: translateX(-200%);
}
.v-window--show-arrows-on-hover:hover .v-window__next,
.v-window--show-arrows-on-hover:hover .v-window__prev {
  transform: translateX(0);
}
.v-window-x-transition-enter-active, .v-window-x-transition-leave-active, .v-window-x-reverse-transition-enter-active, .v-window-x-reverse-transition-leave-active, .v-window-y-transition-enter-active, .v-window-y-transition-leave-active, .v-window-y-reverse-transition-enter-active, .v-window-y-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-window-x-transition-leave, .v-window-x-transition-leave-to, .v-window-x-reverse-transition-leave, .v-window-x-reverse-transition-leave-to, .v-window-y-transition-leave, .v-window-y-transition-leave-to, .v-window-y-reverse-transition-leave, .v-window-y-reverse-transition-leave-to {
  position: absolute !important;
  top: 0;
  width: 100%;
}
.v-window-x-transition-enter {
  transform: translateX(100%);
}
.v-window-x-transition-leave-to {
  transform: translateX(-100%);
}
.v-window-x-reverse-transition-enter {
  transform: translateX(-100%);
}
.v-window-x-reverse-transition-leave-to {
  transform: translateX(100%);
}
.v-window-y-transition-enter {
  transform: translateY(100%);
}
.v-window-y-transition-leave-to {
  transform: translateY(-100%);
}
.v-window-y-reverse-transition-enter {
  transform: translateY(-100%);
}
.v-window-y-reverse-transition-leave-to {
  transform: translateY(100%);
}