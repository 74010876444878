.theme--light.v-calendar-daily {
  background-color: #FFFFFF;
  border-left: #e0e0e0 1px solid;
  border-top: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-head::after {
  background: #e0e0e0;
  background: linear-gradient(90deg, transparent, #e0e0e0);
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day {
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
  color: #000000;
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #424242;
}
.theme--light.v-calendar-daily .v-calendar-daily__day {
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}
.theme--light.v-calendar-daily .v-calendar-daily__interval::after {
  border-top: #e0e0e0 1px solid;
}

.theme--dark.v-calendar-daily {
  background-color: #303030;
  border-left: #9e9e9e 1px solid;
  border-top: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-head::after {
  background: #9e9e9e;
  background: linear-gradient(90deg, transparent, #9e9e9e);
}
.theme--dark.v-calendar-daily .v-calendar-daily_head-day {
  border-right: #9e9e9e 1px solid;
  border-bottom: #9e9e9e 1px solid;
  color: #FFFFFF;
}
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #eeeeee;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day {
  border-right: #9e9e9e 1px solid;
  border-bottom: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}
.theme--dark.v-calendar-daily .v-calendar-daily__interval::after {
  border-top: #9e9e9e 1px solid;
}

.v-calendar-daily {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.v-calendar-daily__head {
  flex: none;
  display: flex;
}

.v-calendar-daily__intervals-head {
  flex: none;
  position: relative;
}
.v-calendar-daily__intervals-head::after {
  position: absolute;
  bottom: 0px;
  height: 1px;
  left: 0;
  right: 0;
  content: "";
}

.v-calendar-daily_head-day {
  flex: 1 1 auto;
  width: 0;
  position: relative;
}

.v-calendar-daily_head-weekday {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 3px 0px 0px 0px;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
}

.v-calendar-daily_head-day-label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0px 0px 3px 0px;
  cursor: pointer;
  text-align: center;
}

.v-calendar-daily__body {
  flex: 1 1 60%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}

.v-calendar-daily__scroll-area {
  overflow-y: scroll;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}

.v-calendar-daily__pane {
  width: 100%;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
}

.v-calendar-daily__day-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.v-calendar-daily__intervals-body {
  flex: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.v-calendar-daily__interval {
  text-align: right;
  padding-right: 8px;
  border-bottom: none;
  position: relative;
}
.v-calendar-daily__interval::after {
  width: 8px;
  position: absolute;
  height: 1px;
  display: block;
  content: "";
  right: 0;
  bottom: -1px;
}

.v-calendar-daily__interval-text {
  display: block;
  position: relative;
  top: -6px;
  font-size: 10px;
  padding-right: 4px;
}

.v-calendar-daily__day {
  flex: 1;
  width: 0;
  position: relative;
}