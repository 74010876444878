.v-time-picker-title {
  color: #FFFFFF;
  display: flex;
  line-height: 1;
  justify-content: flex-end;
}

.v-time-picker-title__time {
  white-space: nowrap;
  direction: ltr;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  align-items: center;
  display: inline-flex;
  height: 70px;
  font-size: 70px;
  justify-content: center;
}

.v-time-picker-title__ampm {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-transform: uppercase;
}
.v-application--is-ltr .v-time-picker-title__ampm {
  margin: 0 0 6px 8px;
}
.v-application--is-rtl .v-time-picker-title__ampm {
  margin: 0 8px 6px 0;
}
.v-time-picker-title__ampm div:only-child {
  flex-direction: row;
}
.v-time-picker-title__ampm--readonly .v-picker__title__btn.v-picker__title__btn--active {
  opacity: 0.6;
}

.v-picker__title--landscape .v-time-picker-title {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.v-picker__title--landscape .v-time-picker-title__time {
  text-align: right;
}
.v-picker__title--landscape .v-time-picker-title__time .v-picker__title__btn,
.v-picker__title--landscape .v-time-picker-title__time span {
  height: 55px;
  font-size: 55px;
}
.v-picker__title--landscape .v-time-picker-title__ampm {
  margin: 16px 0 0;
  align-self: initial;
  text-align: center;
}

.v-picker--time .v-picker__title--landscape {
  padding: 0;
}
.v-picker--time .v-picker__title--landscape .v-time-picker-title__time {
  text-align: center;
}