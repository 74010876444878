.v-input--radio-group legend.v-label {
  cursor: text;
  font-size: 14px;
  height: auto;
}
.v-input--radio-group__input {
  border: none;
  cursor: default;
  display: flex;
  width: 100%;
}
.v-input--radio-group--column .v-input--radio-group__input > .v-label {
  padding-bottom: 8px;
}
.v-input--radio-group--row .v-input--radio-group__input > .v-label {
  padding-right: 8px;
}
.v-input--radio-group--row legend {
  align-self: center;
  display: inline-block;
}
.v-input--radio-group--row .v-input--radio-group__input {
  flex-direction: row;
  flex-wrap: wrap;
}
.v-input--radio-group--column legend {
  padding-bottom: 8px;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: column;
}