.v-chip-group .v-chip {
  margin: 4px 8px 4px 0;
}
.v-chip-group .v-chip--active {
  color: inherit;
}
.v-chip-group .v-chip--active.v-chip--no-color:after {
  opacity: 0.22;
}
.v-chip-group .v-chip--active.v-chip--no-color:focus:after {
  opacity: 0.32;
}

.v-chip-group .v-slide-group__content {
  padding: 4px 0;
}

.v-chip-group--column .v-slide-group__content {
  white-space: normal;
  flex-wrap: wrap;
  max-width: 100%;
}