.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #f5f5f5;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background-color: #272727;
}

.v-sheet.v-app-bar.v-toolbar {
  border-radius: 0;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-app-bar.v-toolbar.v-sheet--shaped {
  border-radius: 24px 0;
}

.v-app-bar:not([data-booted=true]) {
  transition: none !important;
}

.v-app-bar.v-app-bar--fixed {
  position: fixed;
  top: 0;
  z-index: 5;
}

.v-app-bar.v-app-bar.v-app-bar--hide-shadow {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.v-app-bar--fade-img-on-scroll .v-toolbar__image .v-image__image {
  transition: 0.4s opacity cubic-bezier(0.4, 0, 0.2, 1);
}

.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll .v-toolbar__image {
  will-change: opacity;
}
.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--collapse-on-scroll .v-toolbar__extension {
  display: none;
}
.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--is-scrolled .v-toolbar__title {
  padding-top: 9px;
}
.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--is-scrolled:not(.v-app-bar--bottom) .v-toolbar__title {
  padding-bottom: 9px;
}

.v-app-bar.v-app-bar--shrink-on-scroll .v-toolbar__title {
  font-size: inherit;
}

.v-app-bar-title__placeholder, .v-app-bar-title__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-app-bar-title__content {
  position: absolute;
}