.theme--light.v-application {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-application .text--primary {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme--light.v-application .text--secondary {
  color: rgba(0, 0, 0, 0.6) !important;
}
.theme--light.v-application .text--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.theme--dark.v-application {
  background: #121212;
  color: #FFFFFF;
}
.theme--dark.v-application .text--primary {
  color: #FFFFFF !important;
}
.theme--dark.v-application .text--secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}
.theme--dark.v-application .text--disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-application {
  display: flex;
  position: relative;
}
.v-application a {
  cursor: pointer;
}
.v-application--is-rtl {
  direction: rtl;
}
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

@-moz-document url-prefix() {
  @media print {
    .v-application {
      display: block;
    }
    .v-application--wrap {
      display: block;
    }
  }
}