.theme--light.v-overlay {
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-overlay {
  color: #FFFFFF;
}

.v-overlay {
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

.v-overlay__content {
  position: relative;
}

.v-overlay__scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
}

.v-overlay--absolute {
  position: absolute;
}

.v-overlay--active {
  pointer-events: auto;
}