.theme--light.v-stepper {
  background: #FFFFFF;
}
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(0, 0, 0, 0.38);
}
.theme--light.v-stepper .v-stepper__step__step {
  color: white;
}
.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: white;
}
.theme--light.v-stepper .v-stepper__header .v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px black;
}
.theme--light.v-stepper .v-stepper__step--editable:hover {
  background: rgba(0, 0, 0, 0.06);
}
.theme--light.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0px 0px 0px black;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: rgba(0, 0, 0, 0.54);
}
.theme--light.v-stepper .v-stepper__label {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-stepper .v-stepper__label small {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
  color: rgba(0, 0, 0, 0.6);
}
.v-application--is-ltr .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.v-application--is-rtl .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-stepper {
  background: #303030;
}
.theme--dark.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-stepper .v-stepper__step__step {
  color: white;
}
.theme--dark.v-stepper .v-stepper__step__step .v-icon {
  color: white;
}
.theme--dark.v-stepper .v-stepper__header .v-divider {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px white;
}
.theme--dark.v-stepper .v-stepper__step--editable:hover {
  background: rgba(255, 255, 255, 0.06);
}
.theme--dark.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0px 0px 0px white;
}
.theme--dark.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(255, 255, 255, 0.87);
}
.theme--dark.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: rgba(255, 255, 255, 0.75);
}
.theme--dark.v-stepper .v-stepper__label {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-stepper .v-stepper__label small {
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
  color: rgba(255, 255, 255, 0.7);
}
.v-application--is-ltr .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.v-application--is-rtl .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.v-sheet.v-stepper {
  border-radius: 4px;
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-stepper.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-stepper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.v-stepper__header {
  height: 72px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-stepper__header .v-divider {
  align-self: center;
  margin: 0 -16px;
}
.v-stepper__items {
  position: relative;
  overflow: hidden;
}
.v-stepper__step__step {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  width: 24px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-application--is-ltr .v-stepper__step__step {
  margin-right: 8px;
}
.v-application--is-rtl .v-stepper__step__step {
  margin-left: 8px;
}
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1.25rem;
}
.v-stepper__step__step .v-icon.v-icon.v-icon--svg {
  height: 1.25rem;
  width: 1.25rem;
}
.v-stepper__step {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 24px;
  position: relative;
}
.v-stepper__step--active .v-stepper__label {
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-stepper__step--editable {
  cursor: pointer;
}
.v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  background: transparent;
  color: inherit;
}
.v-stepper__step.v-stepper__step--error .v-stepper__step__step .v-icon {
  font-size: 1.5rem;
  color: inherit;
}
.v-stepper .v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: inherit;
  text-shadow: none;
  font-weight: 500;
}
.v-stepper .v-stepper__step.v-stepper__step--error .v-stepper__label small {
  color: inherit;
}
.v-stepper__label {
  display: block;
  flex-grow: 1;
  line-height: 1;
}
.v-application--is-ltr .v-stepper__label {
  text-align: left;
}
.v-application--is-rtl .v-stepper__label {
  text-align: right;
}
.v-stepper__label small {
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
  text-shadow: none;
}
.v-stepper__wrapper {
  overflow: hidden;
  transition: none;
}
.v-stepper__content {
  top: 0;
  padding: 24px 24px 16px 24px;
  flex: 1 0 auto;
  width: 100%;
}
.v-stepper__content > .v-btn {
  margin: 24px 8px 8px 0;
}
.v-stepper--flat {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.v-stepper--is-booted .v-stepper__content, .v-stepper--is-booted .v-stepper__wrapper {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-stepper--vertical {
  padding-bottom: 36px;
}
.v-stepper--vertical .v-stepper__content {
  width: auto;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  padding: 16px 60px 16px 23px;
}
.v-application--is-rtl .v-stepper--vertical .v-stepper__content {
  padding: 16px 23px 16px 60px;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 36px;
}
.v-application--is-rtl .v-stepper--vertical .v-stepper__content {
  margin: -8px 36px -16px -36px;
}
.v-stepper--vertical .v-stepper__step {
  padding: 24px 24px 16px;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__step__step {
  margin-right: 12px;
}
.v-application--is-rtl .v-stepper--vertical .v-stepper__step__step {
  margin-left: 12px;
}
.v-stepper--alt-labels .v-stepper__header {
  height: auto;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 35px -67px 0;
  align-self: flex-start;
}
.v-stepper--alt-labels .v-stepper__step {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 175px;
}
.v-stepper--alt-labels .v-stepper__step small {
  text-align: center;
}
.v-stepper--alt-labels .v-stepper__step__step {
  margin-bottom: 11px;
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 959.98px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: none;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
    margin-left: 0;
    margin-right: 0;
  }
}