.v-color-picker .v-input__slider {
  border-radius: 5px;
}
.v-color-picker .v-input__slider .v-slider {
  margin: 0;
}

.v-color-picker__alpha:not(.v-input--is-disabled) .v-slider {
  border-radius: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
}

.v-color-picker__sliders {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.v-color-picker__dot {
  position: relative;
  height: 30px;
  width: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
  border-radius: 50%;
  overflow: hidden;
}
.v-application--is-ltr .v-color-picker__dot {
  margin-right: 24px;
}
.v-application--is-rtl .v-color-picker__dot {
  margin-left: 24px;
}
.v-color-picker__dot > div {
  width: 100%;
  height: 100%;
}

.v-application--is-ltr .v-color-picker__hue:not(.v-input--is-disabled) {
  background: linear-gradient(to right, #F00 0%, #FF0 16.66%, #0F0 33.33%, #0FF 50%, #00F 66.66%, #F0F 83.33%, #F00 100%);
}
.v-application--is-rtl .v-color-picker__hue:not(.v-input--is-disabled) {
  background: linear-gradient(to left, #F00 0%, #FF0 16.66%, #0F0 33.33%, #0FF 50%, #00F 66.66%, #F0F 83.33%, #F00 100%);
}

.v-color-picker__track {
  position: relative;
  width: 100%;
}

.v-color-picker__preview {
  align-items: center;
  display: flex;
}
.v-color-picker__preview .v-slider {
  min-height: 10px;
}
.v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__thumb {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__track-container {
  opacity: 0;
}
.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue {
  margin-bottom: 24px;
}