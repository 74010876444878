/**
 * * .v-timeline::before - center divider
 * * .v-card::before - arrow shadow (conflicts with active link overlay)
 * * .v-card::after - arrow
 * */
.theme--light.v-timeline::before {
  background: rgba(0, 0, 0, 0.12);
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: #FFFFFF;
}
.theme--light.v-timeline .v-timeline-item .v-card:not(.v-card--link)::before {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.theme--dark.v-timeline::before {
  background: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-timeline .v-timeline-item__dot {
  background: #1E1E1E;
}
.theme--dark.v-timeline .v-timeline-item .v-card:not(.v-card--link)::before {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.v-timeline {
  padding-top: 24px;
  position: relative;
}
.v-timeline::before {
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 2px;
}

.v-timeline-item {
  display: flex;
  padding-bottom: 24px;
}

.v-timeline-item__body {
  position: relative;
  height: 100%;
  flex: 1 1 auto;
}

.v-timeline-item__divider {
  position: relative;
  min-width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-timeline-item__dot {
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  height: 38px;
  left: calc(50% - 19px);
  width: 38px;
}
.v-timeline-item__dot .v-timeline-item__inner-dot {
  height: 30px;
  margin: 4px;
  width: 30px;
}
.v-timeline-item__dot--small {
  height: 24px;
  left: calc(50% - 12px);
  width: 24px;
}
.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 18px;
  margin: 3px;
  width: 18px;
}
.v-timeline-item__dot--large {
  height: 52px;
  left: calc(50% - 26px);
  width: 52px;
}
.v-timeline-item__dot--large .v-timeline-item__inner-dot {
  height: 42px;
  margin: 5px;
  width: 42px;
}

.v-timeline-item__inner-dot {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-timeline-item__opposite {
  flex: 1 1 auto;
  align-self: center;
  max-width: calc(50% - 48px);
}

.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before), .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after {
  flex-direction: row-reverse;
}
.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__opposite, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__opposite {
  text-align: right;
}
.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__opposite, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__opposite {
  text-align: left;
}
.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  left: -10px;
  right: initial;
}
.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  left: initial;
  right: -10px;
}
.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(odd):not(.v-timeline-item--before) .v-timeline-item__body, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body {
  max-width: calc(50% - 48px);
}
.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after), .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before {
  flex-direction: row;
}
.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__opposite, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__opposite {
  text-align: left;
}
.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__opposite, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__opposite {
  text-align: right;
}
.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  right: -10px;
  left: initial;
}
.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  right: initial;
  left: -10px;
}
.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(even):not(.v-timeline-item--after) .v-timeline-item__body, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-timeline-item__body > .v-card:not(.v-card--flat):not(.v-card--link)::before, .v-timeline-item__body > .v-card:not(.v-card--flat)::after {
  content: "";
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black;
  top: calc(50% - 10px);
}
.v-timeline-item__body > .v-card:not(.v-card--flat)::after {
  border-right-color: inherit;
}
.v-timeline-item__body > .v-card:not(.v-card--flat):not(.v-card--link)::before {
  top: calc(50% - 10px + 2px);
}

.v-timeline--align-top .v-timeline-item__dot {
  align-self: start;
}
.v-timeline--align-top .v-timeline-item__body > .v-card::after {
  top: calc(0% + 10px);
}
.v-timeline--align-top .v-timeline-item__body > .v-card:not(.v-card--link)::before {
  top: calc(0% + 10px + 2px);
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)::before {
  left: calc(50% - 1px);
  right: initial;
}
.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)::before {
  left: initial;
  right: calc(50% - 1px);
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense)::before {
  right: calc(50% - 1px);
  left: initial;
}
.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense)::before {
  right: initial;
  left: calc(50% - 1px);
}
.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after), .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before {
  flex-direction: row;
}
.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__opposite, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__opposite {
  text-align: left;
}
.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__opposite, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__opposite {
  text-align: right;
}
.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  right: -10px;
  left: initial;
}
.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  right: initial;
  left: -10px;
}
.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body {
  max-width: calc(50% - 48px);
}
.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before), .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after {
  flex-direction: row-reverse;
}
.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__opposite, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__opposite {
  text-align: right;
}
.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__opposite, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__opposite {
  text-align: left;
}
.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  left: -10px;
  right: initial;
}
.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  left: initial;
  right: -10px;
}
.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-application--is-ltr .v-timeline--reverse.v-timeline--dense::before {
  right: calc(48px - 1px);
  left: initial;
}
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense::before {
  right: initial;
  left: calc(48px - 1px);
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: calc(48px - 1px);
  right: initial;
}
.v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: initial;
  right: calc(48px - 1px);
}

.v-timeline--dense .v-timeline-item {
  flex-direction: row-reverse !important;
}
.v-application--is-ltr .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  left: -10px;
  right: initial;
}
.v-application--is-rtl .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  left: initial;
  right: -10px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 96px);
}
.v-timeline--dense .v-timeline-item__opposite {
  display: none;
}

.v-timeline--reverse.v-timeline--dense .v-timeline-item {
  flex-direction: row !important;
}
.v-application--is-ltr .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-ltr .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card::after {
  transform: rotate(180deg);
  right: -10px;
  left: initial;
}
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link)::before, .v-application--is-rtl .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card::after {
  transform: rotate(0);
  right: initial;
  left: -10px;
}

.v-timeline-item--fill-dot .v-timeline-item__inner-dot {
  height: inherit;
  margin: 0;
  width: inherit;
}