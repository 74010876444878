.theme--light.v-small-dialog__menu-content, .theme--light.v-small-dialog__actions {
  background: #FFFFFF;
}

.theme--dark.v-small-dialog__menu-content, .theme--dark.v-small-dialog__actions {
  background: #1E1E1E;
}

.v-small-dialog {
  display: block;
}
.v-small-dialog__activator {
  cursor: pointer;
}
.v-small-dialog__activator__content {
  display: inline-block;
}
.v-small-dialog__content {
  padding: 0 16px;
}
.v-small-dialog__actions {
  padding: 8px;
  text-align: right;
  white-space: pre;
}