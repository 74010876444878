.v-simple-checkbox {
  align-self: center;
  line-height: normal;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-simple-checkbox .v-icon {
  cursor: pointer;
}

.v-simple-checkbox--disabled {
  cursor: default;
}