.theme--light.v-navigation-drawer {
  background-color: #FFFFFF;
}
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

.theme--dark.v-navigation-drawer {
  background-color: #363636;
}
.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-navigation-drawer .v-divider {
  border-color: rgba(255, 255, 255, 0.12);
}

.v-navigation-drawer {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  pointer-events: auto;
  top: 0;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform, visibility, width;
}
.v-navigation-drawer:not([data-booted=true]) {
  transition: none !important;
}
.v-navigation-drawer.v-navigation-drawer--right:after {
  left: 0;
  right: initial;
}
.v-navigation-drawer .v-list:not(.v-select-list) {
  background: inherit;
}

.v-navigation-drawer__border {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}

.v-navigation-drawer__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-navigation-drawer__image {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  contain: strict;
  width: 100%;
}
.v-navigation-drawer__image .v-image {
  border-radius: inherit;
}

.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 50%;
  top: auto;
  bottom: 0;
  min-width: 100%;
}

.v-navigation-drawer--right {
  left: auto;
  right: 0;
}
.v-navigation-drawer--right > .v-navigation-drawer__border {
  right: auto;
  left: 0;
}

.v-navigation-drawer--absolute {
  z-index: 1;
}

.v-navigation-drawer--fixed {
  z-index: 6;
}

.v-navigation-drawer--absolute {
  position: absolute;
}

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index: 4;
}

.v-navigation-drawer--fixed {
  position: fixed;
}

.v-navigation-drawer--floating:after {
  display: none;
}

.v-navigation-drawer--mini-variant {
  overflow: hidden;
}
.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
  margin-left: 0;
  margin-right: 0;
}
.v-navigation-drawer--mini-variant .v-list-item > *:not(:first-child) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  display: initial;
}
.v-navigation-drawer--mini-variant .v-list-group--no-action .v-list-group__items,
.v-navigation-drawer--mini-variant .v-list-group--sub-group {
  display: none;
}
.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: center;
}

.v-navigation-drawer--temporary {
  z-index: 7;
}

.v-navigation-drawer--mobile {
  z-index: 6;
}

.v-navigation-drawer--close {
  visibility: hidden;
}

.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}