.theme--light.v-picker__title {
  background: #e0e0e0;
}

.theme--dark.v-picker__title {
  background: #616161;
}

.theme--light.v-picker__body {
  background: #FFFFFF;
}

.theme--dark.v-picker__body {
  background: #424242;
}

.v-picker {
  border-radius: 4px;
  contain: layout style;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  vertical-align: top;
  position: relative;
}

.v-picker--full-width {
  display: flex;
  width: 100%;
}
.v-picker--full-width > .v-picker__body {
  margin: initial;
}

.v-picker__title {
  color: #FFFFFF;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px;
}

.v-picker__title__btn {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-picker__title__btn:not(.v-picker__title__btn--active) {
  opacity: 0.6;
  cursor: pointer;
}
.v-picker__title__btn:not(.v-picker__title__btn--active):hover:not(:focus) {
  opacity: 1;
}

.v-picker__title__btn--readonly {
  pointer-events: none;
}

.v-picker__title__btn--active {
  opacity: 1;
}

.v-picker__body {
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.v-picker__body > div {
  width: 100%;
}
.v-picker__body > div.fade-transition-leave-active {
  position: absolute;
}

.v-picker--landscape .v-picker__title {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 170px;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
}
.v-application--is-ltr .v-picker--landscape .v-picker__title {
  left: 0;
}
.v-application--is-rtl .v-picker--landscape .v-picker__title {
  right: 0;
}
.v-application--is-ltr .v-picker--landscape .v-picker__body:not(.v-picker__body--no-title),
.v-application--is-ltr .v-picker--landscape .v-picker__actions:not(.v-picker__actions--no-title) {
  margin-left: 170px;
  margin-right: 0;
}
.v-application--is-rtl .v-picker--landscape .v-picker__body:not(.v-picker__body--no-title),
.v-application--is-rtl .v-picker--landscape .v-picker__actions:not(.v-picker__actions--no-title) {
  margin-right: 170px;
  margin-left: 0;
}

.v-picker--flat {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}