.v-color-picker__swatches {
  overflow-y: auto;
}
.v-color-picker__swatches > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px;
}

.v-color-picker__swatch {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.v-color-picker__color {
  position: relative;
  height: 18px;
  max-height: 18px;
  width: 45px;
  margin: 2px 4px;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
  cursor: pointer;
}
.v-color-picker__color > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}